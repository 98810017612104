/* In HomePage.css */
.layout {
  background: #f0f2f5; /* Colore di sfondo per tutto il layout */
  display: flex;
  flex-direction: column;
  height: 100vh; /* Usa l'altezza completa della viewport */
}

.content {
  flex-grow: 1; /* Permette al content di occupare lo spazio rimanente */
  overflow-y: auto; /* Aggiunge una barra di scorrimento se il contenuto è troppo lungo */
}

.site-layout-content {
  background: rgba(255, 255, 255, 0.8);
  padding: 24px;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
}

.header, .footer {
  background: #001529;
  color: #fff;

}
